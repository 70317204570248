body {
  background-color: #2c2c2c;
  padding: 80px 20px;
}

.App {
  text-align: center;
}

.LandingImage-Frame {
  position: relative;
  height: 400px;
  overflow: hidden;
  width: 80%;
}

.App-logo {
  border: 0;
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Title {
  margin-top: 80px;
  margin-bottom: 20px;
  padding: 20px 40px;
  background-color: #feda00;
  color: #000;
  font-size: 30px;
}

.MintHowManyTitle {
  margin-top: 0;
}

.MintTitle-section {
  max-width: 100%;
  width: 700px;
}

.Mint-section {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Subtitle-section {
  margin-top: 80px;
  display: flex;
  width: 100%;
}
.Subtitle-container {
  display: flex;
  width: 50%;
}

.Supply-text {
  text-align: left;
  margin-right: auto;
}
.Price-text {
  text-align: right;
  margin-left: auto;
}

.image-18 {
  width: 135px;
}

.image-dropbears-logo {
  width: 102px;
}

.social-icon {
  width: 63px;
}

.Footer-container {
  display: flex;
  width: 80%;
  margin: auto;
  margin-top: 80px;
}

.Footer-section {
  display: flex;
  align-items: center;
  width: 33.33333333%;
}

.Text-center {
  justify-content: center;
}

.Text-right {
  justify-content: flex-end;
}

.text-purchase {
  margin-top: 40px;
  font-family: 'Droid Sans', sans-serif;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.MuiButton-contained.Mintbox {
  margin-left: 10px !important;
  background-color: #feda00;
  color: #000;
}

.MuiSelect-outlined.MuiSelect-outlined,
.MuiSelect-select:focus {
  background-color: white !important;
  border-radius: 4px;
}

.MuiFormControl-root {
  min-width: 180px !important;
}

.WalletText {
  font-size: 18px;
  margin-bottom: 80px;
  color: #feda00;
}

.LoadingText {
  font-size: 18px;
  color: #feda00;
}